<template>
    <b-modal id="modal-xl-audio-gallery" v-model="AudioGalleryModelHideShow" hide-footer size="xl" centered title="Audio Gallery"
        @hidden="onModalHidden">
        <div class="modal_content"> 
            <b-row>
                <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
                </b-col>
                <b-col cols="12" md="6">
                    <div class="d-flex align-items-center justify-content-start">
                        <input ref="gallery_image_upload" type="file"
                            accept="audio/*"
                            class="d-none" @change="ImageRendererItems" />
                        <b-button variant="primary" :disabled="isLoading" class="mr-2 d-flex" @click="triggerFileInput">
                            <div class="d-flex">
                                <b-spinner v-if="isLoading" small style="margin-right: 5px;" label="Loading..." />
                                Upload
                            </div>
                        </b-button>
                        <b-form-input v-model="gallerySearchQuery" @input="filterGalleryImages"
                            placeholder="Search..." />
                    </div>
                </b-col>
            </b-row>
            <b-tabs style="height: 430px; overflow: scroll">
 
    <b-tab @click="recent_audio_add_in_gallery(1)" style="text-align: center;">
        <template #title>
            <span>Recently Uploaded</span>
        </template>
        <template v-if="!loadingData">
            <pagination class="m-t-10" :data="RecentAddedAudios" :limit=2
                @pagination-change-page="recent_audio_add_in_gallery">
            </pagination>
            <div>
                <b-row class="match-height">
                    <b-col 
                        v-for="(audio, index) in RecentAddedAudios.data" 
                        :key="'uploaded-'+audio._id"
                        md="2" 
                        lg="2" 
                        style="padding: 8px; position: relative"
                        @click="saveQuestionBackground(audio.path, audio._id)">
                        <PlayAudioComponent
                            :src="audio.path"
                            :title="audio.name.replace(/\.[^/.]+$/, '')" 
                        />
                    </b-col>
                </b-row>
            </div>
        </template>
        <template v-else>
            <b-spinner medium style="margin-right: 5px;" label="Loading..." />
        </template>
    </b-tab>

    <b-tab @click="get_recent_audio_used_from_gallery(1)" style="text-align: center;">
        <template #title>
            <span>Recently Used</span>
        </template>
 
        <template v-if="!loadingDataUsed">
            <pagination class="m-t-10" :data="RecentUsedAudios" :limit=2
                @pagination-change-page="get_recent_audio_used_from_gallery">
            </pagination>
            <div>
                <b-row class="match-height">
                    <b-col 
                        v-for="(audio, index) in RecentUsedAudios.data" 
                        :key="'used-'+audio._id"
                        md="2" 
                        lg="2" 
                        style="padding: 8px; position: relative"
                        @click="saveQuestionBackground(audio.path, audio._id)">
                        <PlayAudioComponent
                            :src="audio.path"
                            :title="audio.name.replace(/\.[^/.]+$/, '')" 
                        />
                    </b-col>
                </b-row>
            </div>
        </template>
        <template v-else>
            <b-spinner medium style="margin-right: 5px;" label="Loading..." />
        </template>
    </b-tab>
</b-tabs>
        </div>
    </b-modal>
</template>

<script>
import {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BFormGroup,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTooltip,
    BTableSimple,
    BThead,
    BTr,
    BFormTextarea,
    BImg,
    BTh,
    BTd,
    BTbody,
    BOverlay,
    BTfoot,
    BTab,
    BTabs,
    BSpinner,
    BFormSelect,
} from "bootstrap-vue";
import "sweetalert2/src/sweetalert2.scss";
import axios from "axios";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import LottieView from "../question/LottieView.vue";
import DoLottieView from "../question/DoLottieView.vue";
import { v4 as uuidv4 } from 'uuid';
export default {
    components: {
        PlayAudioComponent: () => import("../PlayAudioComponent.vue"),
        BCard,
        ToastificationContent,
        BRow,
        BOverlay,
        BImg,
        BCol,
        BFormInput,
        BFormTextarea,
        BButton,
        BTable,
        BMedia,
        BAvatar,
        BFormGroup,
        BLink,
        BBadge,
        BDropdown,
        BDropdownItem,
        BPagination,
        BTooltip,
        BTableSimple,
        BThead,
        BTr,
        BTh,
        BTd,
        BTbody,
        BTfoot,
        BTab,
        BTabs,
        BSpinner,
        BFormSelect,
        LottieView,
        DoLottieView,
        uuidv4
    },
    props: {
        props_hideShowAudiosModel: {
            type: Boolean,
            required: true,
        },
        
    },
    watch: {
        props_hideShowAudiosModel() {
            this.recent_audio_add_in_gallery();
            this.AudioGalleryModelHideShow = this.props_hideShowAudiosModel;
        },
    },
    data() {
        return {
            gallerySearchQuery: "",
            galleryImages: {},
            RecentAddedAudios:{},
            RecentUsedAudios:{},
            AllGalleryImages: [1,2,3,4],
            AudioGalleryModelHideShow: false,
            bearerToken: null,
            isLoading: false,
            loadingData:false,
            loadingDataUsed:false,
            audioUrl:"https://zape02.s3.ap-southeast-1.amazonaws.com/qQszozdRBONQEzIqU5IXmhTwSxxpFLfYXOOIKH2Weg5ZrG7ALWK1SuuUjL8nwILr.mp3",
            optionImageType: [
                { 'value': 0, 'text': '100px' },
                { 'value': 1, 'text': '300px' },
                { 'value': 2, 'text': '500px' },
            ],
            selectImageType: 0,
            localKeyForLottie: this.keyForLottie,
            loadingImage: 'https://i.giphy.com/media/3oEjI6SIIHBdRxXI40/giphy.webp',
            errorImage: 'https://i.giphy.com/media/3oEjI6SIIHBdRxXI40/giphy.webp',
            isPlaying: true,
            currentTime: 0,
            duration: 0,
        };
    },
    created() { 
        this.bearerToken = "Bearer " + localStorage.getItem("_t");
    },
    methods: {
        uuidv4,

        togglePlay() {
    const audio = this.$refs.audio;
    if (!audio.src) return;  
    
    if (this.isPlaying) {
      audio.pause();
    } else {
      audio.play().catch(e => {
        console.error("Audio playback failed:", e);
      });
    }
    this.isPlaying = !this.isPlaying;
        },
        updateTime() {
            this.currentTime = this.$refs.audio.currentTime;
        },
        setDuration() {
            this.duration = this.$refs.audio.duration || 0;
        },
        seekAudio() {
            this.$refs.audio.currentTime = this.currentTime;
       
            if (!this.isPlaying && this.currentTime < this.duration) {
            this.$refs.audio.pause();
            }
        },
        formatTime(time) {
            const minutes = Math.floor(time / 60);
            const seconds = Math.floor(time % 60).toString().padStart(2, "0");
            return `${minutes}:${seconds}`;
        },
        handleAudioEnd() {
            this.isPlaying = false;
            this.currentTime = 0;
        },

        triggerFileInput() {
            this.$refs['gallery_image_upload'].click();
        },

        getFileExtensionFromUrl(url) {
            if (!url) return '';
            const path = url.split('?')[0].split('#')[0];
            const extension = path.split('.').pop();

            if (extension === path) return '';

            return extension;
        },


        async uploadFile(file, url, key, ext) {
           
            try {
                const response = await axios.put(url, file, {
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': file.type,
                    },
                });

                if (response.status !== 200) {
                    console.error('Error uploading file:', response);
                }
                let s3Url = 'https://zape02.s3.ap-southeast-1.amazonaws.com/' + key;
                
                this.saveAudioInGallery(s3Url, file.name, ext)
            } catch (error) {
                this.isLoading = true;
                console.error('Error uploading file:', error);
            }
        },

        async saveAudioInGallery(s3Url, fileName, ext) {
        try {
            if (!this.bearerToken) {
                throw new Error("Authentication failed");
            }

            const galleryObj = {
                name: fileName,
                url: s3Url,
                audioFormat: ext,
            };

            const response = await axios.post(
                `${process.env.VUE_APP_API_URL}/gallery/audio/create`,
                galleryObj,
                {
                    headers: {
                        Authorization: this.bearerToken,
                        "Content-Type": "application/json",
                    }
                }
            );

            this.$emit("saveCategoryAudio", s3Url);
            this.isLoading = false; 
            this.AudioGalleryModelHideShow = false;
        } catch (error) {
            this.isLoading = false;
            console.error(error.response?.data?.message || "Failed to save audio");
        }
    },

        async createGalleryImage(s3Url, fileName, ext) {
            try {
                let galleryObj = {
                    name: fileName,
                    url: s3Url,
                    imageFormat: ext
                }
                await axios.post(process.env.VUE_APP_API_URL + '/gallery/image/create', galleryObj, {
                    headers: {
                        Authorization: this.bearerToken,
                    },
                });
                this.$emit("saveCategoryAudio", s3Url);
                this.isLoading = false; 
                this.AudioGalleryModelHideShow = false;
            } catch (error) {
                this.isLoading = false;
                console.error('Error saving image to gallery', error);
            }
        },
        async ImageRendererItems(event) {
            const file = event.target.files[0];
            if (file) {
                this.isLoading = true;
                try {
                     
                    const ext = file.name.split('.').pop().toLowerCase();
                   
                    const response = await axios.post(process.env.VUE_APP_API_URL + '/s3/generate-presigned-url', {
                        type: file.type,
                        ext: ext
                    }, {
                        headers: {
                            Authorization: this.bearerToken,
                        },
                    });
                    const { url, key } = response.data; 
                    await this.uploadFile(file, url, key, ext);
                } catch (error) {
                    this.isLoading = false;
                    console.error('Error getting pre-signed URL:', error);
                }
            }
        },
 
        getFileExtension(mimeType) {
            const mimeToExtension = {
                'image/jpeg': 'jpg',
                'image/png': 'png',
                'image/gif': 'gif',
                'image/svg+xml': 'svg',
                '.svg': 'svg',
                'application/json': 'json',
                '.json': 'json',
                '.lottie': 'lottie',
                'lottie': 'lottie',
                'audio/mpeg': 'mp3',
                'audio/wav': 'wav',
                'audio/ogg': 'ogg',
                'audio/aac': 'aac',
                'audio/flac': 'flac',
                'audio/x-wav': 'wav',
                'audio/x-m4a': 'm4a',
                'audio/webm': 'webm',
            };
            return mimeToExtension[mimeType] || 'png';
        },

        onModalHidden() {
            this.$emit("audiosModelClose", false);
        },
        saveQuestionBackground(params, id) {
            this.updateLastUseOfAudio(id);
            this.$emit("saveCategoryAudio", params);
            this.AudioGalleryModelHideShow = false;
        },

        async updateLastUseOfAudio(id) {
    try {
        if (!this.bearerToken) {
            throw new Error("Authentication failed");
        }

        const response = await axios.post(
            `${process.env.VUE_APP_API_URL}/gallery/audio/update/last-use`,
            { id },
            {
                headers: {
                    Authorization: this.bearerToken,
                    "Content-Type": "application/json",
                }
            }
        );

        console.log("Audio last use updated:", response.data);
    } catch (error) {
        console.error("Failed to update audio last used time:", error.response?.data?.message || error.message);
    }
},

            get_recent_audio_used_from_gallery(page = 1, query) {
                this.loadingDataUsed = true;
                this.searchQuery = "";
                this.AllGalleryImages = [];
                this.pageNewData = 0;
                this.RecentUsedAudios = {};
                query = this.gallerySearchQuery;

                axios.get(
                    `${process.env.VUE_APP_API_URL}/zape-audio-gallery/recent-used/pagination?page=${page}&query=${query}`,
                    {
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem("_t"),
                        },
                    }
                )
                .then(response => { 
                    if (!response || !response.data) {
                        throw new Error('Invalid server response');
                    }
 
                    if (response.data.success === false) {
                        throw new Error(response.data.error || 'Request failed');
                    }
 
                    if (!response.data.data || !Array.isArray(response.data.data.data)) {
                        throw new Error('Invalid data format received');
                    }
                    this.RecentUsedAudios = response.data.data;
                    this.tabStatus = 'recent'; 
                })
                .catch(error => {
                    console.error("Error fetching recent audio:", error);
 
                    this.RecentUsedAudios = {
                        data: [],
                        meta: {
                            current_page: 1,
                            last_page: 1,
                            total: 0
                        }
                    };
 
                    const errorMsg = error.response?.data?.error || 
                                    error.message || 
                                    'Failed to load recent audio files';
                    
                    this.$toast.error(errorMsg);
                })
                .finally(() => {
                    this.loadingDataUsed = false;
                });
            },

        filterGalleryImages(e) {
            if (this.tabStatus === 'upload') {
                this.recent_audio_add_in_gallery(1, e);
            } else {
                this.get_recent_audio_used_from_gallery(1, e);
            }
        },
        
            async recent_audio_add_in_gallery(page = 1, query = '') {
        try {
            this.loadingData = true;
            this.searchQuery = "";
            this.AllGalleryImages = [];
            this.pageNewData = 0;
            this.RecentAddedAudios = {};
            this.RecentUsedAudios = {};

            query = this.gallerySearchQuery || query;

            const response = await axios.get(
                `${process.env.VUE_APP_API_URL}/zape-audio/recent-add/pagination?page=${page}&query=${query}`,
                {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("_t"),
                    }
                }
            );

            if (response.data && response.data.data) {
                this.RecentAddedAudios = response.data;
                this.tabStatus = 'upload';
            } else {
                console.error("No audio data received");
            }
        } catch (error) {
            const errorMsg = error.response?.data?.error || 
                            error.message || 
                            "Failed to fetch recent audio files";
            console.error(errorMsg);
            
             
        } finally{
            this.loadingData = false;
        }
    },

        get_lottie_images: function (page = 1, query) {
            (this.searchQuery = ""), (this.AllGalleryImages = []);
            (this.pageNewData = 0),
                (this.galleryImages = {}),
                query = this.gallerySearchQuery;
            axios
                .get(process.env.VUE_APP_API_URL + `/zape-cms/lottie-images/pagination?page=${page}&query=${query}`, {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("_t"),
                    },
                })
                .then((get_response) => {
                    this.galleryImages = get_response.data;
                });
        },

    },
};
</script>

<style>
.play-button:hover {
  opacity: 0.8;
  transform: scale(1.05);
  transition: all 0.2s ease;
}
</style>